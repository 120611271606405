<template>
    <div>
        <div class="title">
                  <h2>{{ $t('menu.videos') }}</h2>
                </div>
        <Loading :active="loading" />
        <br />
        <p>
            {{ $t('videos.mensagem') }}
            <strong><a target="blank" href="https://www.instagram.com/coordsgo/">@coordsgo</a></strong>
        </p>
        <p>
            Gostaria de ajudar no Projeto Coords GO, seja um patrocinador PIX
            <strong style="color: #673bb7;">eu@heber.com.br</strong>
        </p>
        <div class="videos">
            <ul class="videos__list">
                <li v-for="(video, index) in videosFilter" :key="index" class="videos__item">
                    <LazyYoutubeVideo class="imgvideo" :src="video.url" preview-image-size="hqdefault"
                        :aspect-ratio="video.aspectRatio" />
                    <div class="canal">
                        <a :href="video.canal" target="blank">{{ video.title }}</a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import LazyYoutubeVideo from 'vue-lazy-youtube-video'
import Loading from '../components/Loading'
import 'vue-lazy-youtube-video/dist/style.css'
import { getAnalytics, logEvent } from 'firebase/analytics'

export default {
    name: 'App',
    components: {
        LazyYoutubeVideo,
        Loading
    },
    computed: {
        videosFilter() {
            return this.videos.filter(i => i.idioma === this.$i18n.locale.split('-')[0]).length
                ? this.videos.filter(i => i.idioma === this.$i18n.locale.split('-')[0])
                : this.videos
        }
    },
    data() {
        return {
            videos: [],
            loading: false
        }
    },
    methods: {
        async getVideos() {
            this.loading = true
            const videos = await axios(`${this.$url}/videos`).catch(() => {
                alert('Aconteceu um erro!')
                this.loading = false
            })
            this.videos = videos.data
            this.loading = false
        }
    },
    mounted() {
        const analytics = getAnalytics(this.$fire)
        logEvent(analytics, 'screen_view', {
            firebase_screen: `APP - ${this.$route.meta.lang} ${this.$route.meta.titulo}`,
            firebase_screen_class: 'Videos'
        })
        document.title = `${this.$route.meta.lang} CoordsGo.com - ${this.$route.meta.titulo}`
        this.getVideos()
    }
}
</script>

<style lang="scss">
.h1 {
    text-align: center;
    color: #34495e;
}

.title {
    h2 {
        display: flex;
        justify-content: space-between;
        color: #673bb7;
        margin: 10px 0;
        font-size: 23px;
        border-bottom: 1px solid #673bb7;
        font-weight: bold;
    }
}

a {
    color: #673bb7;
    text-decoration: none;
}

p {
    text-align: center;
}

.imgvideo {
    border-radius: 10px;

    img {
        border-radius: 10px !important;
    }
}

.canal {
    display: flex;
    justify-content: flex-end;
    margin: 5px;

    a {
        background-color: #673bb7;
        color: #fff;
        padding: 3px 10px;
        border-radius: 5px;
    }
}

.videos {
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-left: 0;

        margin: {
            top: 0;
            bottom: 0;
        }

        list-style: none;
    }

    &__item {
        width: 100%;
        margin-top: 30px;
    }
}
</style>
